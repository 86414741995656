  <template>
    <div>
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #10623f;">
        <div class="container">
          <a class="navbar-brand" href="#">
            <img src="@/assets/logo/logo-putih.png" alt="Your Logo" style="height: 40px; width: auto;">
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="#/pages/index" style="color: #fff;">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" style="color: #fff;">Gallery</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#/pages/basa-eta" style="color: #fff;">Basa Eta</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" style="color: #fff;">Contact</a>
              </li>
              <li class="nav-item" v-if="isLoggedIn">
                <a class="nav-link" @click="logout()" href="#" style="color: #fff;">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    <!-- Hero Section -->
    <div class="c-app-container">
      <div class="c-app-overlay"></div>
        <div class="hero-section d-flex align-items-center" :style="{backgroundImage: 'url(' + require('@/assets/logo/bg.jpeg') + ')', backgroundSize: 'cover', color: '#10623f', height: '800px'}">
          <div class="text-overlay">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 text-center">
                <h1 class="display-4" style="color: #ffffff; font-weight: bold;">BASA ETA !</h1>
                <p class="lead" style="color: #ffffff; font-weight: bold;"> Di sini, kita membangun kembali ikatan, berbagi pengalaman, dan menjelajahi peluang bersama </p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <!-- Visi dan Misi Section -->
      <section id="visi" class="py-5 d-flex align-items-center" style="background-color: #621033; color: #10623f; min-height: 800px;">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <!-- Visi Card -->
              <div class="card mb-4 pt-5 pb-5 card-transparent" style="border-color: #10623f;">
                <div class="card-body">
                  <CCard style=" background-color:#ffffff9c; border-radius: 20px; padding: 20px;">
                  <h3 class="pl-2 pb-4">Moment-moment indah anda yang pernah dibagikan di masa lalu, yuk bagikan disini.</h3>
                  <div class="form-group">
                    <CInput v-model="form.title" placeholder="Judul" />
                    <ckeditor
                      :editor="editor"
                      v-model="form.description"
                      :config="editorConfig"
                    ></ckeditor>
                    <input
                      type="file"
                      class="form-control mb-3 mt-3"
                      id="newData.image"
                      style="height: 45px;"
                      @change="selectImage"
                    />
                  </div>
                  <CRow class="justify-content-end">
                    <CCol col="12" class="text-right">
                      <CButton
                        @click="submit()"
                        class="tombol-submit px-4"
                      >
                      <span class="tombol-text"> Submit</span>
                      </CButton>
                    </CCol>
                  </CRow>
                </CCard>
                </div>
              </div>

              <div class="card mb-4 pt-5 pb-5 card-transparent" style="border-color: #10623f;" >
                <div class="card-body">
                  <h3 class="pl-2 pb-4">Timeline Basa Eta !</h3>
                  <CCard style=" background-color:#ffffff9c; border-radius: 20px; " v-for="(post, index) in posting" :key="index">
                    <CRow no-gutters>
                      <!-- First Column: Image -->
                      <CCol md="6" class="d-flex align-items-center justify-content-center">
                        <CImg :src="post.file" alt="Image" class="card-img pl-3 rounded-image" />
                      </CCol>

                      <!-- Second Column: Title, Content, and Comments -->
                      <CCol md="6">
                        <CCardBody class="custom-card-body d-flex flex-column align-items-center justify-content-center">
                          <!-- Title -->
                          <CCardTitle class="mb-4 font-weight-bold custom-card-title">{{ post.title }}</CCardTitle>

                          <!-- Content -->
                          <CCardText>
                            <p v-html="post.description"></p>
                          </CCardText>
                        </CCardBody>

                        <!-- Comments Section -->
                        
                        <!-- Like and Komentar Section -->
                        <CRow class="p-3">
                          <CCol md="3">
                            <CButton class="tombol w-100 mb-3" @click="like(post.id)">
                              <span class="tombol-text">{{ likesCount[post.id] }}&nbsp<CIcon name="cil-thumb-up"/></span>
                            </CButton>
                          </CCol>
                          <CCol md="9">
                            <CButton class="tombol w-100 mb-3" @click="comment(post.id)">
                              <span class="tombol-text">{{ commentInputs[post.id] ? 'Batal' : 'Komentar' }}</span>  
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCol> 
                    </CRow>
                    <CCard class="m-3">
                      <CCardBody>
                        <h5 class="mb-4">Komentar</h5>
                        <div v-if="commentInputs[post.id]">
                          <!-- CKEditor component for comment input -->
                          <div class="pb-3 pl-3 pr-3">
                            <ckeditor
                              :editor="editor"
                              v-model="commentInputs[post.id]"
                              :config="editorConfig"
                            ></ckeditor>
                          </div>
                          <!-- Button to submit comment -->
                          <!-- Pass the post.id as an argument to the submitComment method -->
                          <CRow class="justify-content-end">
                            <CCol col="12" class="text-right">
                              <CButton class="tombol-submit m-3" @click="submitComment(post.id)">
                                <span class="tombol-text"> Submit Comment</span>
                              </CButton>
                            </CCol>
                          </CRow>
                        </div>
                        
                        <!-- Use v-if to check if there are comments for the current post -->
                        <div v-if="userComments && userComments[post.id] && userComments[post.id].length > 0">
                          <!-- Render comments if they exist -->
                          <div v-for="(comment, cIndex) in userComments[post.id]" :key="cIndex" class="comment-container">
                            <div class="comment-header">
                              <strong>{{ comment.member.nickname }}</strong>
                              <span class="comment-date"> • {{ formatDate(comment.created_at) }}</span>
                            </div>
                            <div class="comment-body">
                              <p v-html="comment.description"></p>
                            </div>
                          </div>
                        </div>

                        <!-- Use v-else to display a message when there are no comments for the current post -->
                        <div v-else>
                          <p>Belum Ada Komentar ! Jadilah yang pertama untuk berkomentar.</p>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Footer -->
      <footer class="text-light py-4" style="background-color:#10623f ; color: #fff;">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p>&copy; 2023 Your Brand. All rights reserved.</p>
            </div>
            <div class="col-md-6 text-right">
              <a href="#" class="text-light">Privacy Policy</a> | <a href="#" class="text-light">Terms of Service</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </template>

  <script>
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import { uploadImage } from "@/utils/fileUpload"
  import FileSaver from "file-saver"
  import firebase from "firebase/compat/app"
  import "firebase/auth"
  import store from '@/store';
  import router from '@/router';

    export default {
      components: {
        ckeditor: CKEditor.component,
      },
      data() {
        return {
          selectedItems: [],
          file: null,
          editor: ClassicEditor,
          fotoKomentar: '',
          form: {
            title: '',
            description: '',
            file: '', 
          },
          editorConfig: {
            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
          },
          commentInputs: {},
          posting: [],
          likes: [],
          commentar: [],
          likesCount: {},
          userComments: {},
          params: {
            sorttype: "desc",
            sortby: "id",
            row: 6,
            page: 1,
            keyword: "",
          },
        };
      },
      methods: {
        formatDate(date) {
          // Implement your date formatting logic here
          // Example: return new Date(date).toLocaleDateString();
          return this.$moment(date).format("Do MMMM YYYY")
         
        },
        selectImage(event) {
          this.file = event.target.files[0];
          var loading = this.$loading.show();
          uploadImage(this.file)
            .then((resp) => {
              this.form.file = resp;  
              loading.hide();
              alert("File berhasil diupload !!");
            })
            .catch((e) => {
              loading.hide();
              alert("Terjadi kesalahan !! | " + e);
            });
        },
        submit() {
          // Retrieve the current user's data from local storage
          const currentUser = JSON.parse(localStorage.getItem('user'));

          // Check if the user is logged in
          if (!currentUser || !currentUser.id) {
            this.$toast.error("You must be logged in to like a post.");
            router.push({ name: 'Login User' });
            return;
          }
        
          var loading = this.$loading.show();
          // Check if a file is selected
          if (this.file) {
            // Upload the image file
            uploadImage(this.file)
              .then((imagePath) => {
                // Set the image path to the form data
                this.form.photo = imagePath;
                // Continue with the submission
                this.submitPosting(loading);
              })
              .catch((e) => {
                loading.hide();
                this.$toast.error("Error uploading the image.");
              });
          } else {
            // No file selected, proceed with the submission without the image
            this.submitPosting(loading);
          }
        },
        getPosting() {
            this.$store
              .dispatch("posting/getPosting", this.params)
              .then((resp) => {
                this.posting = resp.data.data
              })
              .catch((e) => {
                console.log(e)
              })
          },
          getLikes() {
            this.$store
              .dispatch("likes/getLikes", this.params)
              .then((resp) => {
                this.likes = resp.data.data
              })
              .catch((e) => {
                console.log(e)
              })
          },
          getCommentar() {
            this.$store
              .dispatch("commentar/getCommentar", this.params)
              .then((resp) => {
                this.commentar = resp.data.data
              })
              .catch((e) => {
                console.log(e)
              })
          },
          getLikesCount(postingId) {
            this.$store
              .dispatch("likes/getLikesCount", postingId)
              .then((resp) => {
                this.$set(this.likesCount, postingId, resp.data.data);
              })
              .catch((e) => {
                console.log(e);
              });
          },
          getUserCommentar(postingId) {
            this.$store
              .dispatch("commentar/getUserCommentar", postingId)
              .then((resp) => {
                this.$set(this.userComments, postingId, resp.data.data.data);
              })
              .catch((e) => {
                console.log(e);
              });
          },

          selectImageKomentar(event) {
            this.file = event.target.files[0];
            var loading = this.$loading.show();
            uploadImage(this.file)
              .then((resp) => {
                this.fotoKomentar = resp;  
                loading.hide();
                alert("File berhasil diupload !!");
              })
              .catch((e) => {
                loading.hide();
                alert("Terjadi kesalahan !! | " + e);
              });
          },
          submitKomentar() {
            var loading = this.$loading.show();
            // Check if a file is selected
            if (this.file) {
              // Upload the image file
              uploadImage(this.file)
                .then((imagePath) => {
                  // Set the image path to the form data
                  this.form.photo = imagePath;
                  // Continue with the submission
                  this.submitPosting(loading);
                })
                .catch((e) => {
                  loading.hide();
                  this.$toast.error("Error uploading the image.");
                });
            } else {
              // No file selected, proceed with the submission without the image
              this.submitPosting(loading);
            }
          },
          submitPosting(loading) {
            // Retrieve the current user's data from local storage
            const currentUser = JSON.parse(localStorage.getItem('user'));

            // Check if the user is logged in
            if (!currentUser || !currentUser.id) {
              this.$toast.error("You must be logged in to like a post.");
              router.push({ name: 'Login User' });
              return;
            }
            
            // Insert data into the database
            this.$store
              .dispatch("posting/addPosting", this.form)
              .then(() => {
                this.$toast.success("Berhasil menambahkan survey");
                this.form.title = '';
                this.form.description = '';
                this.form.file = '';
                this.getPosting()
                loading.hide();
              })
              .catch((e) => {
                console.error(e)
              });
          },

          like(postingId) {
          // Retrieve the current user's data from local storage
            const currentUser = JSON.parse(localStorage.getItem('user'));

            // Check if the user is logged in
            if (!currentUser || !currentUser.id) {
              this.$toast.error("You must be logged in to like a post.");
              router.push({ name: 'Login User' });
              return;
            }

            // Obtain the current user's ID from the user data
            const currentUserId = currentUser.id;

            // Construct the payload for adding likes
            const likePayload = {
              posting_id: postingId,
              user_id: currentUserId,
            };

            // Dispatch the action to add likes
            this.$store
              .dispatch("likes/addLikes", likePayload)
              .then(() => {
                this.$toast.success("Berhasil menambahkan like");

                // Optionally, you can update the likes count or fetch the updated data
                this.getLikesCount(postingId);
                
              })
              .catch((e) => {
                console.error(e);
              });
          },
          comment(postingId) {
            this.$set(this.commentInputs, postingId, !this.commentInputs[postingId]);
          },
          submitComment(postingId) {
              // Get the user data from local storage
              const currentUser = JSON.parse(localStorage.getItem('user'));

              // Check if the user is logged in
              if (!currentUser || !currentUser.id) {
                  this.$toast.error("You must be logged in to submit a comment.");
                  router.push({ name: 'Login User' });
                  return;
              }

              // Get the current user's ID from the user data
              const currentUserId = currentUser.id;

              // Construct the payload for adding a comment
              const commentPayload = {
                  user_id: currentUserId,
                  description: this.commentInputs[postingId],
                  file: "example.jpg", // You may adjust this based on your requirements
                  posting_id: postingId,
              };

              // Dispatch the action to add a comment
              this.$store
                  .dispatch("commentar/addCommentar", commentPayload)
                  .then(() => {
                      this.$toast.success("Comment submitted successfully.");

                      // Optionally, you can update the comments or fetch the updated data
                      this.getUserCommentar(postingId);
                      // Clear the comment input field after submission
                      this.commentInputs[postingId] = '';
                  })
                  .catch((e) => {
                      console.error(e);
                  });
          },

          getData() {
            this.getPosting(),
            this.getLikes(),
            this.getLikesCount(),
            this.getCommentar(),
            this.getUserCommentar()
          },
          logout() {
            this.$store.dispatch("auth/logout").then(() => {
              this.$toast.success("Logout Berhasil");
            });
          },
      },
      computed:  {
        isLoggedIn() {
            // Check if a token exists in the local storage
            console.log(localStorage.getItem('token_user'))
            return localStorage.getItem('token_user') !== null;
          },
      },
      mounted() {
        this.getData()
      },
      watch: {
        posting: {
          handler(newVal) {
            newVal.forEach(post => {
              this.getLikesCount(post.id);
              this.getUserCommentar(post.id);
            });
          },
          deep: true,
        },
      },
    };
  </script>

<style>
  .custom-card-body {
    min-height: 300px; /* Set a minimum height to center vertically */
  }

  .custom-card-title {
    font-size: 28px; /* Adjust the font size as needed */
    font-weight: bolder;
    color: #333;
  }
  .tombol {
    border-radius: 10px; /* Match the border-radius of other buttons */
    background-color: rgb(16, 98, 63);
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }

  .tombol-submit {
    border-radius: 10px; /* Match the border-radius of other buttons */
    background-color: rgb(16, 98, 63);
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease;
  }

  .tombol:hover {
    background-color: #14532d; /* Darker shade on hover */
    color: #ffffff73;
  }

  .tombol-text {
    margin-left: 8px;
    font-weight: bold;
  }

  .comment-container {
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }

  .comment-container:nth-child(even) {
    background-color: #f9f9f9;
  }

  .comment-container:hover {
    background-color: #e6f7ff;
  }

  .comment-header {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .comment-body {
    line-height: 1.5;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .comment-date {
    color: #888;
    font-size: 0.8em;
  }
  .rounded-image {
    border-radius: 20px; 
    margin: 10px;
    margin-left:30px;
    margin-top:30px;/* You can adjust the value to control the level of rounding */
  }
  .editor-container {
    width: 100%; /* Adjust the width as needed */
    height: 400px; /* Adjust the height as needed */
  }
  /* Add your custom styles here */

  /* Navbar */
  .navbar {
    background-color: #343a40;
  }

  /* Hero Section */
  .hero-section {
    padding: 100px 0;
  }

  /* Visi dan Misi Section */
  #visi-misi {
    border-top: 5px solid #10623f;
    border-bottom: 5px solid #10623f;
  }

  /* Pengumuman and Gallery Section */
  #pengumuman,
  #gallery {
    padding: 60px 0;
    min-height: 800px;
  }

  .feature-section h2 {
    color: #10623f;
  }

  /* Footer */
  .footer {
    background-color: #10623f;
    color: #fff;
  }

  .c-app-container {
      position: relative;
    }

    .hero-section {
    position: relative;
    padding: 100px 0;
  }

  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Place the text overlay above the background overlay */
  }

  .tebel {
    font-weight: bold;
  }

  .c-app-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity here */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  .card-transparent {
    background-color: rgba(255, 255, 255, 0.9); /* Adjust the opacity as needed */
  }

  @media (max-width: 768px) {
      /* Adjust the height for smaller screens */
      .hero-section,
      #visi,
      #misi,
      #gallery,
      #pengumuman,
      #pengurus {
        height: 600px; /* Adjust the height for smaller screens */
      }
    }

    @media (max-width: 768px) {
      /* Adjust the height for smaller screens */
      #pengurus {
        min-height: 1600px; /* Adjust the height for smaller screens */
      }
    }
  </style>
